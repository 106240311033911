/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { CommonModule, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from "@angular/core";
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
  RoutesRecognized,
} from "@angular/router";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzModalService } from "ng-zorro-antd/modal";
import { MeusDadosComponent } from "../private/meus-dados/meus-dados.component";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzButtonModule } from "ng-zorro-antd/button";
import { PageUtils } from "../core/services/page-utils.service";
import { TemplateStore } from "./data-access/template.store";
import { NgIconsModule } from "@ng-icons/core";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ReportarProblemaComponent } from "./reportar-problema/reportar-problema.component";
import { MenuGroup } from "./data-access/template.model";
import { criarMenu } from "./utils/criar-menu";
import { LogoutService } from "../auth/logout.service";
import { TokenStore } from "../auth/token.store";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { AdminNotificacoesSinoComponent } from "../admin-v2/ui/admin-notificacoes-sino/admin-notificacoes-sino.component";

@Component({
  selector: "app-template",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    RouterLink,

    NgIconsModule,
    NgTemplateOutlet,

    AdminNotificacoesSinoComponent,

    NzButtonModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzIconModule,
    NzLayoutModule,
    NzMenuModule,
    NzNotificationModule,
    NzSelectModule,
    NzToolTipModule,
  ],
  templateUrl: "./template.component.html",
  styleUrl: "./template.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzModalService, TemplateStore],
})
export class TemplateComponent {
  public store = inject(TemplateStore);
  private readonly tokenStore = inject(TokenStore, { skipSelf: true });
  public readonly breakpoint = inject(PageUtils).breakpoint;
  private readonly router = inject(Router);
  private readonly modalService = inject(NzModalService);
  private readonly logoutService = inject(LogoutService);

  possuiUsuarioOrgao = computed(() => this.tokenStore.possuiUsuarioOrgao());
  possuiPermissoes = computed(() => this.tokenStore.permissoes().length > 0);
  isAuthenticated = computed(() => this.tokenStore.isValidToken());
  termoAssinado = computed(() => this.tokenStore.termoAssinado());
  siglaOrgao = computed(() => this.tokenStore.siglaOrgao());
  nomeUsuario = computed(() => this.tokenStore.nomeUsuario());

  menu = computed<MenuGroup[]>(() =>
    this.tokenStore.token() ? criarMenu(this.tokenStore) : [],
  );

  constructor() {
    this.router.events.subscribe((event) => {
      this.store.updateHasUsuarioOrgao();

      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        this.store.setCurrentRoute(currentRoute);
      }

      if (event instanceof RoutesRecognized) {
        if (event.state.root.children[0].children.length > 0) {
          this.store.setModuloAtivo(
            event.state.root.firstChild?.firstChild?.data["nomeModulo"],
          );
        }
      }
    });
  }

  abrirMeusDados() {
    this.modalService.create({
      nzTitle: "Meus dados",
      nzContent: MeusDadosComponent,
      nzFooter: null,
    });
  }

  logout() {
    this.logoutService.logoutCompleto();
  }

  redirectTo(rota: string) {
    this.router.navigate([rota]);
  }

  reportarProblema() {
    this.modalService.create({
      nzTitle: "Reportar problema",
      nzContent: ReportarProblemaComponent,
      // nzWidth: 500,
      nzFooter: null,
    });
  }
}
