<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<nz-layout class="relative h-full overflow-hidden">
    @if (possuiUsuarioOrgao() && termoAssinado()) {
        @if (breakpoint.sm()) {
            <nz-sider
                nzCollapsible
                [nzCollapsed]="store.isCollapsed()"
                (nzCollapsedChange)="store.setCollapsed($event)"
                [nzTrigger]="
                    !store.isCollapsed() && !breakpoint.sm() ? undefined : null
                "
                nzBreakpoint="sm"
                [nzCollapsedWidth]="breakpoint.sm() ? 64 : 0"
                nzWidth="256px"
                nzTheme="dark"
                [class]="breakpoint.sm() ? '' : 'absolute z-20 h-full'"
            >
                <div
                    [ngClass]="[
                        'flex h-[64px] w-full items-center gap-1.5 px-6 text-white',
                        store.isCollapsed()
                            ? 'justify-center'
                            : 'justify-start',
                    ]"
                >
                    <img
                        src="assets/svg/logo-ab-light.svg"
                        alt="logo ab"
                        class="h-[22px] w-auto"
                    />
                    @if (!store.isCollapsed()) {
                        @defer (on timer(100)) {
                            <span class="font-play text-xl tracking-wide"
                                >Alerta Brasil</span
                            >
                        }
                    }
                </div>
                <div
                    class="max-h-[calc(100vh-64px)] overflow-y-auto"
                    [class]="breakpoint.sm() ? '' : 'pb-20'"
                >
                    <ng-container
                        *ngTemplateOutlet="abMenuItems"
                    ></ng-container>
                </div>
            </nz-sider>
        } @else {
            <nz-drawer
                nzClosable="false"
                [nzVisible]="!store.isCollapsed()"
                nzPlacement="left"
                [nzTitle]="abMenuTitle"
                [nzWidth]="256"
                (nzOnClose)="store.setCollapsed(true)"
                [nzBodyStyle]="{
                    padding: 0,
                    backgroundColor: '#002242',
                }"
            >
                <ng-container *nzDrawerContent>
                    <div class="overflow-y-auto">
                        <ng-container
                            *ngTemplateOutlet="abMenuItems"
                        ></ng-container>
                    </div>
                </ng-container>
            </nz-drawer>
        }
    }

    <nz-layout>
        @if (isAuthenticated()) {
            <nz-header class="flex flex-none items-center justify-between px-4">
                <div class="flex items-center">
                    @if (possuiUsuarioOrgao() && possuiPermissoes()) {
                        <button
                            nz-button
                            nzType="text"
                            (click)="store.setCollapsed(!store.isCollapsed())"
                            class="px-0 text-white hover:text-white/90 focus:text-white/90 focus:ring-1 focus:ring-white/90"
                        >
                            <span
                                class="text-lg"
                                nz-icon
                                [nzType]="
                                    store.isCollapsed()
                                        ? 'menu-unfold'
                                        : 'menu-fold'
                                "
                            ></span>
                        </button>
                    }
                    @if (breakpoint.sm() || store.isCollapsed()) {
                        <span class="ml-2 text-lg text-white">{{
                            store.moduloAtivo()
                        }}</span>
                    }
                </div>

                <div class="flex items-center gap-2">
                    <app-admin-notificacoes-sino></app-admin-notificacoes-sino>
                    <button
                        nz-button
                        nzType="text"
                        nz-dropdown
                        [nzDropdownMenu]="userMenu"
                        nzTrigger="click"
                        nzPlacement="bottomRight"
                        class="h-12 p-0 text-white hover:text-white/90 focus:text-white/90 focus:ring-1 focus:ring-white/90"
                    >
                        <div class="flex h-full items-center gap-2">
                            <span
                                nz-icon
                                nzType="user"
                                nzTheme="outline"
                                class="text-2xl"
                            ></span>
                            @if (breakpoint.sm()) {
                                <div class="flex flex-col">
                                    <span class="flex text-base">{{
                                        nomeUsuario()
                                    }}</span>
                                    <span class="flex text-xs">{{
                                        siglaOrgao()
                                    }}</span>
                                </div>
                            }

                            <span nz-icon nzType="down"></span>
                        </div>
                    </button>
                </div>
            </nz-header>
        }
        <nz-content class="flex-1 overflow-auto">
            <router-outlet></router-outlet>
        </nz-content>
    </nz-layout>
</nz-layout>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu>
        @if (possuiUsuarioOrgao()) {
            <li
                nz-menu-item
                class="h-10"
                (click)="abrirMeusDados()"
                (keydown.enter)="abrirMeusDados()"
                tabindex="0"
            >
                <span nz-icon nzType="solution"></span>
                <span class="ml-2">Dados pessoais</span>
            </li>
            <li nz-menu-item class="h-10" [routerLink]="'solicitar-acesso'">
                <span nz-icon nzType="credit-card"></span>
                <span class="ml-2">Acessos</span>
            </li>
            <nz-divider class="my-1"></nz-divider>
        }
        <li
            nz-menu-item
            class="h-10"
            (click)="logout()"
            (keydown.enter)="logout()"
            tabindex="0"
        >
            <span nz-icon nzType="api" nzTheme="outline"></span>
            <span class="ml-2">Sair</span>
        </li>
    </ul>
</nz-dropdown-menu>

<ng-template #abMenuTitle>
    <div
        [ngClass]="[
            '-mx-6 -my-4 flex h-16 items-center gap-1.5 bg-[#002242] px-6 text-white',
            store.isCollapsed() ? 'justify-center' : 'justify-start',
        ]"
    >
        <img
            src="assets/svg/logo-ab-light.svg"
            alt="logo ab"
            class="h-[22px] w-auto"
        />

        <span class="font-play text-xl tracking-wide">Alerta Brasil</span>
    </div>
</ng-template>

<ng-template #abMenuItems>
    <ul nz-menu nzTheme="dark" nzMode="inline">
        @for (menuGroup of menu(); track $index) {
            @if (!store.isCollapsed()) {
                <span class="flex px-6 pb-2 pt-4 text-xs opacity-80">{{
                    menuGroup.displayName
                }}</span>
            }
            @for (option of menuGroup.itens; track $index) {
                <li
                    nz-menu-item
                    [routerLink]="option.route"
                    [nzSelected]="option.route === store.currentRoute()"
                    (click)="!store.isCollapsed() && store.setCollapsed(true)"
                >
                    @if (option.iconType === "nz-icon") {
                        <span nz-icon [nzType]="option.iconName"></span>
                    } @else if (option.iconType === "ng-icon") {
                        <ng-icon [name]="option.iconName"></ng-icon>
                    }
                    @if (!store.isCollapsed()) {
                        <span>{{ option.displayName }}</span>
                    }
                    @if (option.badge) {
                        @if (store.isCollapsed()) {
                            <span
                                class="absolute left-8 top-2.5 size-1.5 rounded-full bg-blue-500 saturate-150"
                            ></span>
                        } @else {
                            <div class="relative inline-flex">
                                <span
                                    class="absolute bottom-1 left-1.5 text-3xs font-bold tracking-wider text-blue-400 saturate-150"
                                    >{{ option.badge }}</span
                                >
                            </div>
                        }
                    }
                </li>
            }
            <nz-divider class="my-2 border-white/10"></nz-divider>
        }
    </ul>
    <ul nz-menu nzTheme="dark" nzMode="inline" nzSelectable="false">
        <li nz-menu-item (click)="reportarProblema()">
            <span nz-icon nzType="bug"></span>
            @if (!store.isCollapsed()) {
                <span> Reportar problema </span>
            }
        </li>
    </ul>
</ng-template>
